import IconClose from '~/components/icons/streamline/line/IconClose';

export default function CloseText({ children = 'Close' }: { children?: string }) {
  return (
    <div className="text-theme-faint hover:text-theme-primary cursor-pointer close flex items-center space-x-1 leading-none uppercase transition">
      <span className="size-4 close">
        <IconClose />
      </span>
      <span className="text-xs close">{children}</span>
    </div>
  );
}
